import React, { useEffect, useState, useCallback, useContext, useRef } from 'react';
import { Container, Accordion, Table, Spinner, Alert, Button, Modal, Form  } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { BillingContext } from './BillingContext';
import Select from 'react-select';
import LoadingOverlay from './LoadingOverlay'; // Import the LoadingOverlay component

const API_BASE_URL = 'https://app.yyzprep.ca/api';
const API_TOKEN = 'Yh7l5CUTaZ1nIgAueWglafvm616hchHFFZxRjKjPHNBjB19b2jTDgGoCSpeq';
const LOCAL_API_URL = process.env.REACT_APP_API_URL;

const BOX_RATE = 1.29; // Rate per box
// New services for USA shipments based on weight
const USA_WEIGHT_SERVICES = [
  { name: 'USA | Less than 10lb', maxWeightLbs: 10 },
  { name: 'USA | Between 10lb and 20lb', maxWeightLbs: 20 },
  { name: 'USA | Between 21lb and 40lb', maxWeightLbs: 40 },
  { name: 'USA | More than 40lb', maxWeightLbs: Infinity },
];

const ClientDetail = () => {

  // Add this state for customer selection
  // State Initialization
  const [selectedSquareCustomer, setSelectedSquareCustomer] = useState(null);
  const [squareCustomerOptions, setSquareCustomerOptions] = useState([]);
  const [applyCcAdminFee, setApplyCcAdminFee] = useState(false);
  const [applyMinimumAdjustment, setApplyMinimumAdjustment] = useState(false);

const [invoiceNotes, setInvoiceNotes] = useState('');
const { teamId, startDate, endDate } = useParams();
const { setClientShipments } = useContext(BillingContext);

const [loading, setLoading] = useState(true); // Default to true for initial loading state

// Shipment and Client Data
const [detailedShipments, setDetailedShipments] = useState([]);
const [clients, setClients] = useState([]);
const [boxData, setBoxData] = useState([]);

// Rates and Fees
const [rateSheet, setRateSheet] = useState([]);
const [servicesAdded, setServicesAdded] = useState([]);
const [serviceCharges, setServiceCharges] = useState([]);
const [totalBillWithTax, setTotalBillWithTax] = useState(0);

// Boxes and Pallets
const [standardBoxes, setStandardBoxes] = useState([]);
const [boxUsage, setBoxUsage] = useState({});
const [totalBoxesOverall, setTotalBoxesOverall] = useState(0);
const [totalUnitsShipped, setTotalUnitsShipped] = useState(0);
const [waivedBoxes, setWaivedBoxes] = useState(0);

// UI State
const [expandedService, setExpandedService] = useState(null);
const [warnings, setWarnings] = useState([]);
const [showStorageModal, setShowStorageModal] = useState(false);
const [pallets, setPallets] = useState('');
const [cbm, setCbm] = useState('');
const [customAmount, setCustomAmount] = useState('');
const [clientTier, setClientTier] = useState(1);
const [fbaIds, setFbaIds] = useState([]);

const [billableBoxes, setBillableBoxes] = useState(0);
const [billableBoxesAdded, setBillableBoxesAdded] = useState(false);

//Custom Button
const [showCustomServiceModal, setShowCustomServiceModal] = useState(false);
const [customServiceName, setCustomServiceName] = useState('');
const [customServiceRate, setCustomServiceRate] = useState('');
const [customServiceQuantity, setCustomServiceQuantity] = useState('');



// Tax State
const [selectedTaxes, setSelectedTaxes] = useState({});
const [applyTaxToAll, setApplyTaxToAll] = useState(false);

// Fetch State Flags
const [dataFetched, setDataFetched] = useState({
  clients: false,
  rateSheet: false,
  standardBoxes: false,
});

// Calculation Flags
const [calculationsCompleted, setCalculationsCompleted] = useState({
  usaWeightFees: false,
  standardBoxes: false,
  palletDeliveries: false,
});

const formatFbaIdsForNotes = (fbaIds) => {
  return fbaIds.length > 0 ? `FBA Shipment IDs:\n${fbaIds.join('\n')}` : 'No FBA Shipment IDs found.';
};

useEffect(() => {
  const loadBoxData = async () => {
    const boxData = await fetchBoxes();
    setBoxData(boxData);
  };

  loadBoxData();
}, []);



const handleAddCustomService = () => {
  const customService = {
    serviceId: `custom-${Date.now()}`, // Unique ID based on timestamp
    name: customServiceName,
    charge: parseFloat(customServiceRate),
    quantity: parseInt(customServiceQuantity, 10),
    totalCharge: parseFloat(customServiceRate) * parseInt(customServiceQuantity, 10),
  };

  setServicesAdded((prevServices) => [...prevServices, customService]);

  // Reset modal fields and close modal
  setCustomServiceName('');
  setCustomServiceRate('');
  setCustomServiceQuantity(1);
  setShowCustomServiceModal(false);

  // Recalculate service charges
  calculateServiceCharges([...servicesAdded, customService]);
};



const fetchBoxes = async () => {
  try {
    const response = await fetch(`${LOCAL_API_URL}/api/boxes`);
    if (!response.ok) {
      throw new Error('Failed to fetch boxes data');
    }
    const boxData = await response.json();
    return boxData;
  } catch (error) {
    console.error('Error fetching boxes:', error);
    return [];
  }
};


  useEffect(() => {
    if (applyCcAdminFee) {
      calculateCcAdminFee(totalBillWithTax);
    } else {
      calculateServiceCharges(servicesAdded); // Recalculate without the fee
    }
  }, [applyCcAdminFee, totalBillWithTax]);

  const calculateMinimumAdjustmentFee = () => {
    const totalAmount = totalBillWithTax;
    const minimumAdjustment = 500 - totalAmount;
  
    if (minimumAdjustment > 0) {
      const service = {
        serviceId: 'minimum-adjustment-fee',
        name: 'Monthly Minimum Adjustment',
        charge: minimumAdjustment,
        type: 'minimum-adjustment-fee',
        shipmentId: 'N/A',
        origin: 'minimum-adjustment',
        quantity: 1,
        totalCharge: minimumAdjustment,
      };
  
      setServicesAdded((prevServices) => {
        const updatedServices = [
          ...prevServices.filter(service => service.serviceId !== 'minimum-adjustment-fee'),
          service
        ];
        return updatedServices;
      });
      
      setApplyMinimumAdjustment(true);
    }
  };
  

  const calculateCcAdminFee = (totalAmount) => {
    const ccAdminFee = totalAmount * 0.024;
    const eTransferAmount = (totalAmount - ccAdminFee).toFixed(2);
    const eTransferMessage = `To avoid paying the CC Admin Fee, you can send an eTransfer of $${eTransferAmount} to hello@yyzprep.ca and send us the password if applicable.\n\n`;
  
    if (applyCcAdminFee) {
      const service = {
        serviceId: 'cc-admin-fee',
        name: 'CC Admin Fee',
        charge: ccAdminFee,
        type: 'cc-admin-fee',
        shipmentId: 'N/A',
        origin: 'cc-admin-fee',
        quantity: 1,
        totalCharge: ccAdminFee,
      };
  
      setServicesAdded((prevServices) => {
        const updatedServices = [
          ...prevServices.filter(service => service.serviceId !== 'cc-admin-fee'),
          service
        ];
        return updatedServices;
      });
  
      // Replace any existing eTransfer message with the updated one
      setInvoiceNotes(prevNotes => {
        const newNotes = prevNotes.replace(/To avoid paying the CC Admin Fee, you can send an eTransfer of \$[0-9.]+ to hello@yyzprep.ca and send us the password if applicable\.\n\n/, '');
        return `${eTransferMessage}${newNotes}`;
      });
  
    } else {
      setServicesAdded(prevServices => prevServices.filter(service => service.serviceId !== 'cc-admin-fee'));
  
      // Remove the eTransfer message if the fee is removed
      setInvoiceNotes(prevNotes => prevNotes.replace(eTransferMessage, ''));
    }
  };
  
  

// Function to extract FBA IDs from shipments
const extractFbaIds = (shipments) => {
  const fbaIdList = [];
  console.log("Getting FBA IDs...");
  shipments.forEach((shipment) => {
    if (shipment.plans && shipment.plans.length > 0) {
      // Extract FBA IDs from plans
      shipment.plans.forEach((plan) => {
        if (plan.fba_shipment_id) {
          fbaIdList.push(plan.fba_shipment_id);
        }
      });
    } else {
      // Extract FBA ID from marketplace_data if no plans
      console.log(shipment);
      const marketplaceData = JSON.parse(shipment.outboundInfo.shipment.marketplace_data);
      if (marketplaceData && marketplaceData.shipment_id) {
        fbaIdList.push(marketplaceData.shipment_id);
      }
    }
  });

  return fbaIdList;
};


// Delay function using Promise
const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const getRateForService = (serviceName, clientTier = 1) => {
    const rateEntry = rateSheet.find((entry) => entry.SERVICE_NAME === serviceName);
    if (!rateEntry) return 0;

    // Determine the tier field name based on the clientTier
    const tierField = `TIER_${clientTier}_RATE`;

    // Return the rate for the specified tier
    return parseFloat(rateEntry[tierField]) || 0;
  };

  const calculateUSAWeightFees = async (shipments, clients) => {
    let allUSAWeightFees = []; 
  
    for (const shipment of shipments) {
      const client = clients.find((client) => client.id === shipment.outboundInfo.shipment.team_id);
      if (!client || !client.name.includes("(US)")) {
        continue; 
      }
  
      let usaWeightFees = []; 
      if (shipment.plans && shipment.plans.length > 0) {
        for (const plan of shipment.plans) {
          const boxGroups = plan.box_groups;
          boxGroups.forEach((boxGroup) => {
            const weightLbs = gmToLbs(boxGroup.weight_gm); 
            const service = USA_WEIGHT_SERVICES.find(
              (s) => weightLbs <= s.maxWeightLbs
            );
            if (service) {
              const rate = getRateForService(service.name);
              const quantity = boxGroup.box_numbers.length || 1; 
              if (rate > 0) {
                usaWeightFees.push({
                  serviceId: service.name,
                  name: service.name,
                  charge: rate,
                  type: "usa-weight-fee",
                  shipmentId: shipment.id,
                  origin: "weight-check",
                  quantity: quantity,
                  totalCharge: rate * quantity,
                });
              }
            }
          });
        }
      } else {
        const boxContents = await fetchBoxContents(shipment.id);
        const uniqueBoxWeights = {};
        boxContents.forEach((box) => {
          const { BOX_NUMBER, WEIGHT } = box;
          if (!uniqueBoxWeights[BOX_NUMBER]) {
            uniqueBoxWeights[BOX_NUMBER] = WEIGHT;
          }
        });
  
        Object.keys(uniqueBoxWeights).forEach((boxNumber) => {
          const weightKg = uniqueBoxWeights[boxNumber];
          const weightLbs = kgToLbs(weightKg);
          const service = USA_WEIGHT_SERVICES.find(
            (s) => weightLbs <= s.maxWeightLbs
          );
  
          if (service) {
            const rate = getRateForService(service.name);
            if (rate > 0) {
              usaWeightFees.push({
                serviceId: service.name,
                name: service.name,
                charge: rate,
                type: 'usa-weight-fee',
                shipmentId: shipment.id,
                origin: 'weight-check',
                quantity: 1, 
                totalCharge: rate,
              });
            }
          }
        });
      }
  
      allUSAWeightFees.push(...usaWeightFees);
    }
  
    const aggregatedFees = aggregateUSAWeightFees(allUSAWeightFees);
  
    setServicesAdded((prevServices) => {
      const newServices = [...prevServices, ...aggregatedFees];
      return JSON.stringify(prevServices) !== JSON.stringify(newServices) ? newServices : prevServices;
    });
  };
  
// Helper function to aggregate USA weight fees
const aggregateUSAWeightFees = (fees) => {
const feeMap = new Map();

fees.forEach((fee) => {
const key = `${fee.serviceId}-${fee.shipmentId}`;

if (feeMap.has(key)) {
  const existingFee = feeMap.get(key);
  existingFee.quantity += fee.quantity;
  existingFee.totalCharge += fee.totalCharge;
} else {
  feeMap.set(key, { ...fee });
}
});

return Array.from(feeMap.values());
};

const fetchData = async () => {
  setLoading(true); // Start loading

  try {
    const [squareResponse, clientResponse, rateSheetResponse, standardBoxResponse] = await Promise.all([
      fetch(`${LOCAL_API_URL}/api/square/customers`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      }),
      fetch(`${LOCAL_API_URL}/api/get_clients`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      }),
      fetch(`${LOCAL_API_URL}/api/rate-sheet`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      }),
      fetch(`${LOCAL_API_URL}/api/get_standard_boxes`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    ]);

    console.log("Clients are now set to", clients);
    // Check if all responses are OK
    if (
      !squareResponse.ok ||
      !clientResponse.ok ||
      !rateSheetResponse.ok ||
      !standardBoxResponse.ok
    ) {
      throw new Error('Failed to fetch one or more resources');
    }

    const squareCustomers = await squareResponse.json();
    const clientList = await clientResponse.json();
    const rateSheet = await rateSheetResponse.json();
    const standardBoxes = await standardBoxResponse.json();
    setClients(clientList);

    setSquareCustomerOptions(
      squareCustomers.map((customer) => ({
        value: customer.id,
        email: customer.emailAddress,
        companyName: customer.companyName,
        label: `${customer.companyName} (${customer.emailAddress})`,
      }))
    );

    
    setRateSheet(rateSheet);
    setStandardBoxes(standardBoxes);

    // Update fetch flags
    setDataFetched({
      clients: true,
      rateSheet: true,
      standardBoxes: true,
    });
    calculateServiceCharges(detailedShipments);

  } catch (error) {
    
    console.error('Error fetching data:', error);
  } finally {
    setLoading(false); // Ensure loading state is turned off
  }
};

useEffect(() => {
  const totalAmount = calculateTotalBillWithTax();
  
  if (totalAmount !== totalBillWithTax) {
    setTotalBillWithTax(totalAmount);
  }

  // Apply minimum adjustment if total is below $500 and not already applied
  if (totalAmount < 500 && !applyMinimumAdjustment) {
    calculateMinimumAdjustmentFee();
  }
}, [serviceCharges, selectedTaxes, totalBillWithTax, applyMinimumAdjustment]);



  // Function to generate invoice
  const generateInvoice = async () => {
    if (!selectedSquareCustomer) {
      alert('Please select a Square customer to add invoice items.');
      return;
    }
  
    try {
      const invoiceLineItems = serviceCharges.map((charge) => {
        // Ensure unitPrice and quantity are valid numbers
        const unitPrice = parseFloat(charge.rate);
        const quantity = parseInt(charge.quantity, 10);
  
        if (isNaN(unitPrice) || isNaN(quantity)) {
          throw new Error('Invalid unit price or quantity');
        }
  
        // Check if tax is selected for this service
        const applyTax = selectedTaxes[charge.serviceId];
  
        return {
          description: charge.serviceName,
          quantity,
          unitPrice,
          applyTax, // Boolean to indicate if tax should be applied
        };
      });
      console.log(selectedSquareCustomer);
      const invoiceData = {
        customerId: selectedSquareCustomer.value,
        customerEmail: selectedSquareCustomer.email, // Assuming email is part of the selected customer data
        notes: invoiceNotes,
        lineItems: invoiceLineItems,
        companyName: selectedSquareCustomer.companyName
      };
      
  
      const response = await fetch(`${LOCAL_API_URL}/api/create-invoice`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(invoiceData),
      });
  
      if (!response.ok) {
        throw new Error('Failed to create invoice');
      }
  
      const invoiceResponse = await response.json();
      alert('Invoice created successfully.');
      console.log('Invoice Response:', invoiceResponse);
  
      // Extract the invoice ID from the response
      const invoiceId = invoiceResponse.invoice.id;
      const locationId = 'LHC59K9B425QJ'; // Your location ID
  
      // Construct the URL to the specific invoice
      const invoiceUrl = `https://app.squareup.com/dashboard/invoices/${invoiceId}/edit?currentUnitToken=${locationId}`;
      window.open(invoiceUrl, '_blank');
      
      // Optionally, reset the form
      setSelectedSquareCustomer(null);
      setInvoiceNotes('');
    } catch (error) {
      console.error('Error creating invoice:', error);
      alert('Failed to create invoice.');
    }
  };
  
  useEffect(() => {
    // Recalculate service charges whenever servicesAdded changes
    calculateServiceCharges(servicesAdded);
    console.log('Service charges changed', serviceCharges.length);
  }, [servicesAdded]);

  useEffect(() => {
    const totalAmount = calculateTotalBillWithTax();
    if (totalAmount !== totalBillWithTax) {
      setTotalBillWithTax(totalAmount);
    }
  }, [serviceCharges, selectedTaxes, totalBillWithTax]);
  
  useEffect(() => {
    // Ensure FDA charges are recalculated when shipments or other relevant states change
    if (detailedShipments.length > 0) {
      calculateServiceCharges(detailedShipments);
      fetchFDAServiceAmount(); // Make sure this function is called appropriately
    }
  }, [detailedShipments, clientTier]); // Add any additional dependencies as needed


  useEffect(() => {
    if (detailedShipments.length > 0) {
      const fbaIdList = extractFbaIds(detailedShipments);
      const shipmentsWithoutFba = detailedShipments.filter(shipment => {
        // Check if the shipment has plans with FBA IDs
        const hasFbaId = shipment.plans && shipment.plans.some(plan => plan.fba_shipment_id);
        return !hasFbaId;
      });
  
      // Create a list of shipment names and IDs for those without FBA IDs
      const shipmentNamesAndIds = shipmentsWithoutFba.map(shipment => `Shipment ID: ${shipment.id}`);
  
      // Merge both lists
      const mergedNotes = [
        ...fbaIdList.map(fbaId => `FBA ID: ${fbaId}`),
        ...shipmentNamesAndIds,
      ].join('\n');
  
      setFbaIds(fbaIdList); // Store the FBA IDs if needed elsewhere
      setInvoiceNotes(mergedNotes); // Automatically set the invoice notes with both FBA IDs and shipment names + IDs
    }
  }, [detailedShipments]);
  

  const fetchFDAServiceAmount = async (clientNameBase, startDate, endDate) => {
    try {
      const response = await fetch("https://hook.us1.make.com/ykt4b7hx2iyz4a6dh1acax88sj9pg5xu", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          client_name: clientNameBase,
          start_date: startDate,
          end_date: endDate,
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to fetch FDA service amount");
      }

      const amount = await response.text(); // Read the response as a plain number
      return parseFloat(amount); // Convert the response to a number
    } catch (error) {
      console.error("Error fetching FDA service amount:", error);
      return 0; // Return 0 if there's an error
    }
  };


  const handleAddStorageFee = () => {
    let calculatedCbm = 0;
    let amount = 0;

    // Calculate CBM based on user input
    if (pallets) {
      calculatedCbm = parseFloat(pallets) * 1.61; // 1 pallet = 1.61 CBM
    } else if (cbm) {
      calculatedCbm = parseFloat(cbm);
    } else if (customAmount) {
      amount = parseFloat(customAmount);
      calculatedCbm = amount / 50 * 1.61; // Calculate CBM from custom amount
    }

    // Calculate the total charge
    amount = (calculatedCbm / 1.61) * 50; // $50 per 1.61 CBM (or per pallet)

    // Create a new service entry
    const service = {
      serviceId: `storage-fee`, // Ensure a unique ID for each service
      name: `Storage Fee (CBM)`,
      charge: amount, // Set the charge as the total amount for this service
      type: 'storage-charge',
      shipmentId: 'N/A',
      origin: 'storage-fee',
      quantity: 1, // Quantity is always 1
      totalCharge: amount, // Total charge calculated based on CBM
    };

    // Update the services state
    setServicesAdded((prevServices) => {
      const updatedServices = [...prevServices, service];
      // Recalculate service charges based on updated services
      calculateServiceCharges(updatedServices);
      return updatedServices;
    });

    // Reset form fields
    setPallets('');
    setCbm('');
    setCustomAmount('');

    // Close the modal
    setShowStorageModal(false);
  };

    

  // Fetch client list from the API
  const fetchClientList = async () => {
    try {
      const response = await fetch(`${LOCAL_API_URL}/api/get_clients`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error('Failed to fetch client list');
      }

      const clientList = await response.json();
      setClients(clientList); 
      return clientList;
    } catch (error) {
      console.error('Error fetching client list:', error);
      return [];
    }
  };

  const fetchAndCalculateFDANotice = async () => {
    try {
      let totalFDACharge = 0;
      
      // Group shipments by base client name
      const shipmentsByBaseClient = detailedShipments.reduce((acc, shipment) => {
        const client = clients.find(client => client.id === shipment.outboundInfo.shipment.team_id);
        if (!client) {
          console.error(`Client not found for team_id: ${shipment.outboundInfo.shipment.team_id}`);
          return acc;
        }
  
        const clientNameBase = client.name.replace(/(\s*\(CA\)|\s*\(US\))$/i, '');
  
        if (!acc[clientNameBase]) {
          acc[clientNameBase] = [];
        }
  
        acc[clientNameBase].push(shipment);
        return acc;
      }, {});
  
      // Iterate over each base client to calculate FDA Prior Notices
      for (const clientNameBase in shipmentsByBaseClient) {
        if (shipmentsByBaseClient.hasOwnProperty(clientNameBase)) {
          const fdaServiceAmount = await fetchFDAServiceAmount(clientNameBase, startDate, endDate);
  
          if (fdaServiceAmount > 0) {
            const fdaTotalCharge = fdaServiceAmount * 1.75; // $1.75 per service
            totalFDACharge += fdaTotalCharge;
  
            setServicesAdded((prevServices) => [
              ...prevServices,
              {
                serviceId: "fda-prior-notice",
                name: "FDA Prior Notice Service",
                charge: 1.75,
                type: "fda-charge",
                shipmentId: "N/A",
                origin: "fda-service",
                quantity: fdaServiceAmount,
                totalCharge: fdaTotalCharge,
                tax: 0,
              },
            ]);
          }
        }
      }
  
      console.log(`Total FDA Prior Notice Charge: $${totalFDACharge.toFixed(2)}`);
    } catch (error) {
      console.error('Error calculating FDA Prior Notice:', error);
    }
  };
  
  
  // Fetch and calculate pallet deliveries for the client
  const fetchAndCalculatePalletDeliveries = async (clientName, startDate, endDate) => {
    try {
      // Remove any country suffix to match the base company name
      const clientNameBase = clientName.replace(/(\s*\(CA\)|\s*\(US\))$/i, '');
  
      // Make a call to the local API to fetch delivery log data
      const response = await fetch(
        `${LOCAL_API_URL}/api/delivery-log`, {
          method: 'POST', // Use POST if needed by your server
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            company: clientNameBase,
            startDate,
            endDate,
          }),
        }
      );
  
      if (!response.ok) {
        throw new Error('Failed to fetch delivery log data');
      }
  
      const deliveryLogData = await response.json();
  
      // Filter rows where TIME_OF_SCAN falls within the date range
      // and TRACKING_CODE starts with the specified prefixes
      const filteredDeliveries = deliveryLogData.filter((entry) => {
        const timeOfScan = new Date(entry.TIME_OF_SCAN);
        const trackingCodePattern = /^MANUAL_ENTRY_\d-Pallet/; // Regular expression to match any digit after 'MANUAL_ENTRY_'
        const alternatePattern = /^MANUAL_ENTRY_\d--/; // Regular expression for the other pattern
  
        return (
          timeOfScan >= new Date(startDate) &&
          timeOfScan <= new Date(endDate) &&
          (trackingCodePattern.test(entry.TRACKING_CODE) || alternatePattern.test(entry.TRACKING_CODE))
        );
      });
  
      // Count the number of pallet deliveries
      const palletCount = filteredDeliveries.length;
      console.log(filteredDeliveries);
  
      // Calculate the total charge for pallet deliveries
      const totalCharge = palletCount * 10;
  
      // If there are any pallet deliveries, add them to the service charges
      if (palletCount > 0) {
        setServicesAdded((prevServices) => [
          ...prevServices,
          {
            serviceId: 'ltl-delivery-receiving',
            name: 'Delivery Receiving (LTL)',
            charge: 10,
            type: 'pallet-charge',
            shipmentId: 'N/A', // Not tied to a specific shipment',
            origin: 'pallet-delivery',
            quantity: palletCount,
            totalCharge: totalCharge,
            tax: 0,
          },
        ]);
      }
  
      return { palletCount, totalCharge };
    } catch (error) {
      console.error('Error calculating pallet deliveries:', error);
      return { palletCount: 0, totalCharge: 0 };
    }
  };
  

  // Fetch rate sheet data from the backend
  const fetchRateSheet = async () => {
    try {
      const response = await fetch(`${LOCAL_API_URL}/api/rate-sheet`);
      if (!response.ok) {
        throw new Error('Failed to fetch rate sheet data');
      }
      const data = await response.json();
      setRateSheet(data);
    } catch (error) {
      console.error('Error fetching rate sheet:', error);
    }
  };

  const prevDetailedShipments = useRef();
  const prevClients = useRef();
  
  useEffect(() => {
    console.log('useEffect [dataFetched, detailedShipments, clients] called');
    
    // Ensure calculations only run when all necessary data is fetched
    if (dataFetched.clients && dataFetched.rateSheet && dataFetched.standardBoxes) {
      if (
        JSON.stringify(prevDetailedShipments.current) !== JSON.stringify(detailedShipments) ||
        JSON.stringify(prevClients.current) !== JSON.stringify(clients)
      ) {
        console.log('Running calculations: calculateServiceCharges, calculateUSAWeightFees, calculateStandardBoxes, calculatePalletDeliveries');
        calculateServiceCharges(detailedShipments);
        calculateUSAWeightFees(detailedShipments, clients);
        calculateStandardBoxes(detailedShipments);
        calculatePalletDeliveries(detailedShipments, clients);
        
        // Update previous values
        prevDetailedShipments.current = detailedShipments;
        prevClients.current = clients;
      } else {
        console.log('Data has not changed, skipping calculations.');
      }
    }
  }, [dataFetched, detailedShipments, clients]);
  
  
  useEffect(() => {
    console.log('useEffect [calculationsCompleted, detailedShipments] called');
    
    if (calculationsCompleted.usaWeightFees && calculationsCompleted.standardBoxes) {
      console.log('Running calculateServiceCharges');
      const updatedCharges = calculateServiceCharges(detailedShipments);
      
      // Only update if the new charges are different from the current state
      if (JSON.stringify(serviceCharges) !== JSON.stringify(updatedCharges)) {
        console.log('Updating serviceCharges state');
        setServiceCharges(updatedCharges);
      }
    }
  }, [calculationsCompleted, detailedShipments]); // Removed `serviceCharges` from dependencies
  
  useEffect(() => {
    console.log('useEffect [serviceCharges, selectedTaxes] called');
    
    // Only calculate the total bill if service charges have changed
    if (serviceCharges.length > 0) {
      const totalAmount = calculateTotalBillWithTax();
      console.log('Setting totalBillWithTax:', totalAmount);
      setTotalBillWithTax(totalAmount);
    }
  }, [serviceCharges, selectedTaxes]); // No change needed if the dependencies are correct
  
  
  useEffect(() => {
    console.log('useEffect [clientTier, detailedShipments] called');
    
    // Recalculate service charges when clientTier changes
    if (detailedShipments.length > 0) {
      console.log('Running calculateServiceCharges due to clientTier change');
      calculateServiceCharges(detailedShipments);
    }
  }, [clientTier, detailedShipments]);
  
  useEffect(() => {
    console.log('useEffect [teamId, startDate, endDate] called');
    
    // Fetch shipments when teamId, startDate, or endDate changes
    if (teamId && startDate && endDate) {
      console.log('Fetching client shipments');
      fetchClientShipments();
    }
  }, [teamId, startDate, endDate]);

  // Fetch standard box dimensions
  const fetchStandardBoxes = async () => {
    try {
      const response = await fetch(`${LOCAL_API_URL}/api/get_standard_boxes`);
      if (!response.ok) {
        throw new Error('Failed to fetch standard box dimensions');
      }
      const data = await response.json();
      setStandardBoxes(data);
    } catch (error) {
      console.error('Error fetching standard boxes:', error);
    }
  };

  // Fetch box contents for shipments without plans
  const fetchBoxContents = async (shipmentId) => {
    try {
      const response = await fetch(
        `${LOCAL_API_URL}/api/box-contents?outbound_id=${shipmentId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch box contents");
      }

      const boxContents = await response.json();

      return boxContents;
    } catch (error) {
      console.error("Error fetching box contents:", error);
      return [];
    }
  };

  // Extract unique boxes from box contents
// Corrected getUniqueBoxes function
const getUniqueBoxes = (boxContents) => {
  console.log('Box Contents:', boxContents);
  const uniqueBoxesMap = new Map();

  boxContents.forEach((item) => {
    const box = {
      length: parseInt(item.LENGTH),
      width: parseInt(item.WIDTH),
      height: parseInt(item.HEIGHT),
      boxNumber: item.BOX_NUMBER, // Track the box number
    };

    // Check if a box with the same box number already exists
    if (!uniqueBoxesMap.has(box.boxNumber)) {
      uniqueBoxesMap.set(box.boxNumber, box);
    }
  });

  // Convert the map values to an array to return the unique boxes
  const uniqueBoxes = Array.from(uniqueBoxesMap.values());

  console.log('Unique Boxes:', uniqueBoxes);
  return uniqueBoxes;
};

  // Function to determine client tier based on total units shipped
  const determineClientTier = (totalUnitsShipped) => {
    if (totalUnitsShipped < 500) {
      return 1; // Tier 1
    } else if (totalUnitsShipped < 1250) {
      return 2; // Tier 2
    } else if (totalUnitsShipped < 2500) {
      return 3; // Tier 3
    } else {
      return 4; // Tier 4
    }
  };

  // Function to convert kilograms to pounds
  const kgToLbs = (kg) => kg * 2.20462;

  // Function to convert grams to pounds
  const gmToLbs = (gm) => gm / 453.59237;

  // Function to calculate the total units shipped
  const calculateTotalUnitsShipped = (shipments) => {
    return shipments.reduce(
      (total, shipment) => total + shipment.shipped_items_count,
      0
    );
  };

  // Helper function to calculate waived boxes based on unit labels
  const calculateWaivedBoxes = (unitLabels) => Math.floor(unitLabels / 100);

  const calculateServiceCharges = (shipments) => {
    const totalUnitsShipped = calculateTotalUnitsShipped(shipments);

    // Determine and set the client tier
    const autoClientTier = determineClientTier(totalUnitsShipped);
    setClientTier((prevTier) => prevTier || autoClientTier); // Only set once if not manually changed

    const charges = {};
    servicesAdded.forEach((service) => {
        let rateEntry = rateSheet.find(
            (entry) => entry.SERVICE_ID === service.serviceId
        );

        // Use the rate corresponding to the selected client tier
        let rate = rateEntry
            ? rateEntry[`TIER_${clientTier}_RATE`] // Ensure the correct tier rate is used
            : service.charge;

        let quantity = service.quantity || 1;

        // **New logic for "Shipping Charge" service**
        if (service.name.toLowerCase() === 'shipping charge' && quantity > 100000) {
            rate = 1.00; // Increase rate to $1.00
            quantity = Math.ceil(quantity / 100); // Divide quantity by 100
        }

        const totalCharge = rate * quantity;

        if (!charges[service.name]) {
            charges[service.name] = {
                serviceName: service.name,
                serviceId: service.serviceId,
                rate: rate,
                quantity: 0,
                totalCharge: 0,
                tax: 0,
                breakdown: [],
            };
        }

        charges[service.name].quantity += quantity; // Accumulate quantity correctly
        charges[service.name].totalCharge += totalCharge;

        charges[service.name].breakdown.push({
            shipmentId: service.shipmentId,
            quantity: quantity,
            asin: service.asin,
            fnsku: service.fnsku,
            title: service.title,
            merchantSku: service.merchantSku,
            totalCharge: totalCharge,
        });
    });

    setServiceCharges(Object.values(charges));
};

  const calculateStandardBoxes = async (shipments) => {
    const boxUsage = {};
    const totalBoxesCount = {};
    let totalBillableBoxes = 0;

    console.log('Calculating Standard Boxes for Shipments:', shipments);
    console.log('Standard Boxes:', standardBoxes);

    for (const shipment of shipments) {
        const plans = shipment.plans;

        if (plans.length > 0) {
            // If there are plans, use box_groups from each plan
            console.log("Shipment ID has plans:", shipment.id);
            const boxGroups = plans.flatMap((plan) => plan.box_groups);
            boxGroups.forEach((boxGroup) => {
                const boxGroupLength = Math.round(boxGroup.length_mm / 10);
                const boxGroupWidth = Math.round(boxGroup.width_mm / 10);
                const boxGroupHeight = Math.round(boxGroup.height_mm / 10);

                const matchingBox = standardBoxes.find((standardBox) => {
                    const boxLength = parseInt(standardBox.LENGTH, 10);
                    const boxWidth = parseInt(standardBox.WIDTH, 10);
                    const boxHeight = parseInt(standardBox.HEIGHT, 10);

                    return (
                        (boxLength === boxGroupLength && boxWidth === boxGroupWidth && boxHeight === boxGroupHeight) ||
                        (boxLength === boxGroupLength && boxWidth === boxGroupHeight && boxHeight === boxGroupWidth) ||
                        (boxLength === boxGroupWidth && boxWidth === boxGroupLength && boxHeight === boxGroupHeight) ||
                        (boxLength === boxGroupWidth && boxWidth === boxGroupHeight && boxHeight === boxGroupLength) ||
                        (boxLength === boxGroupHeight && boxWidth === boxGroupLength && boxHeight === boxGroupWidth) ||
                        (boxLength === boxGroupHeight && boxWidth === boxGroupWidth && boxHeight === boxGroupLength)
                    );
                });

                const boxCount = boxGroup.box_numbers && boxGroup.box_numbers.length > 0 ? boxGroup.box_numbers.length : 1;
                console.log("Matching Box:", matchingBox ? matchingBox.NAME : "No Match", "Count:", boxCount);

                if (!totalBoxesCount[shipment.id]) {
                    totalBoxesCount[shipment.id] = 0;
                }
                totalBoxesCount[shipment.id] += boxCount;

                if (matchingBox) {
                    if (!boxUsage[matchingBox.NAME]) {
                        boxUsage[matchingBox.NAME] = 0;
                    }

                    boxUsage[matchingBox.NAME] += boxCount;

                    if (matchingBox.CHARGE === "YES") {
                        totalBillableBoxes += boxCount;
                    }
                }
            });
        } else {
            const uniqueBoxes = await fetchBoxContents(shipment.id);
            const allBoxes = getUniqueBoxes(uniqueBoxes);
            console.log("Shipment ID has no plans:", shipment.id, "Unique Boxes:", allBoxes);

            allBoxes.forEach((box) => {
                const matchingBox = standardBoxes.find((standardBox) => {
                    const boxLength = Math.round(standardBox.LENGTH);
                    const boxWidth = Math.round(standardBox.WIDTH);
                    const boxHeight = Math.round(standardBox.HEIGHT);

                    return (
                        (boxLength === box.length && boxWidth === box.width && boxHeight === box.height) ||
                        (boxLength === box.length && boxWidth === box.height && boxHeight === box.width) ||
                        (boxLength === box.width && boxWidth === box.length && boxHeight === box.height) ||
                        (boxLength === box.width && boxWidth === box.height && boxHeight === box.length) ||
                        (boxLength === box.height && boxWidth === box.length && boxHeight === box.width) ||
                        (boxLength === box.height && boxWidth === box.width && boxHeight === box.length)
                    );
                });

                if (!totalBoxesCount[shipment.id]) {
                    totalBoxesCount[shipment.id] = 0;
                }
                totalBoxesCount[shipment.id] += 1;

                if (matchingBox) {
                    console.log("Matching Box:", matchingBox.NAME);

                    if (!boxUsage[matchingBox.NAME]) {
                        boxUsage[matchingBox.NAME] = 0;
                    }

                    boxUsage[matchingBox.NAME] += 1;

                    if (matchingBox.CHARGE === "YES") {
                        totalBillableBoxes += 1;
                    }
                }
            });
        }
    }

    console.log('Total Standard Boxes Used:', boxUsage);

    const totalBoxesUsed = Object.values(totalBoxesCount).reduce((sum, count) => sum + count, 0);
    const totalUnitsShipped = calculateTotalUnitsShipped(shipments);
    const waivedBoxes = calculateWaivedBoxes(totalUnitsShipped);
    const billableBoxes = Math.max(0, totalBillableBoxes - waivedBoxes);

    // Store the calculated billable boxes in the state
    setBillableBoxes(billableBoxes);

    // Update state variables
    setBoxUsage(boxUsage);
    setTotalBoxesOverall(totalBoxesUsed);
    setTotalUnitsShipped(totalUnitsShipped);
    setWaivedBoxes(waivedBoxes);

    console.log('Total Units Shipped:', totalUnitsShipped);
    console.log('Waived Boxes:', waivedBoxes);
    console.log('Billable Boxes:', billableBoxes);
};



  // Modify the function that processes the shipment items
  const processOutboundItems = async (
    shipment,
    outboundItems,
    servicesAdded
  ) => {
    for (const item of outboundItems.items) {
      const quantity = item.quantity; // Get the quantity from the item
      const asin = item.item.asin;
      const fnsku = item.item.fnsku;
      const merchantSku = item.item.merchant_sku;
      const title = item.item.title;
      const weightGm = item.item.weight_gm; // Weight in grams

      // Check if the item has an "Oversized" or "Huge Oversized" service
      const isOversized = item.company_services.some(
        (service) =>
          service.name.toLowerCase() === 'oversized' ||
          service.name.toLowerCase() === 'huge oversized'
      );

      if (isOversized) {
        // Fetch ASIN weight from the database if it has an oversized service
        let weightKg = null;
        try {
          const response = await fetch(
            `${LOCAL_API_URL}/api/asin-weight/${asin}`
          );
          const weightData = await response.json();
          if (weightData) {
            weightKg = weightData.WEIGHT; // Weight is in kg
          }
        } catch (error) {
          console.error('Error fetching ASIN weight:', error);
        }

        // Fallback to using the outbound item weight if ASIN weight is not found
        let weightLbs;
        if (weightKg !== null) {
          weightLbs = kgToLbs(weightKg); // Convert kg to lbs
        } else {
          weightLbs = gmToLbs(weightGm); // Convert grams to lbs
        }

        // Log the received weight in pounds
        console.log(`Item ASIN: ${asin}, Weight: ${weightLbs.toFixed(2)} lbs`);

        // Check if the item weight exceeds 5 lbs and calculate additional charges
        if (weightLbs > 5) {
          const extraWeightLbs = weightLbs - 5;
          const additionalCharge = 0.1 * extraWeightLbs;

          // Log if the weight falls under the > 5lb category and charges being added
          console.log(
            `Oversized Item ASIN: ${asin}, Weight: ${weightLbs.toFixed(
              2
            )} lbs, Extra Weight: ${extraWeightLbs.toFixed(
              2
            )} lbs, Quantity: ${quantity}, Additional Charge: $${additionalCharge.toFixed(
              2
            )}`
          );

          // Add the additional charge as a service
          servicesAdded.push({
            serviceId: 'oversized-fee', // Unique identifier for this service
            name: 'Additional Oversized > 5lb Fees',
            charge: additionalCharge,
            type: 'extra-charge',
            shipmentId: shipment.id,
            origin: 'weight-check',
            quantity, // The quantity of items
            asin, // ASIN of the item
            fnsku, // FNSKU of the item
            title, // Title of the item
            merchantSku, // Merchant SKU of the item
          });
        }
      }

      // Existing logic to add company services

      item.company_services.forEach((service) => {
        servicesAdded.push({
          serviceId: service.id,
          name: service.name,
          charge: service.charge,
          type: service.type,
          shipmentId: shipment.id,
          origin: 'outboundItem',
          quantity,
          asin,
          fnsku,
          title,
          merchantSku,
        });
      });

      if (Array.isArray(item.always_company_services) && item.always_company_services.length > 0) {
        item.always_company_services.forEach((service) => {
          servicesAdded.push({
            serviceId: service.id,
            name: service.name,
            charge: service.charge,
            type: service.type,
            shipmentId: shipment.id,
            origin: 'outboundItem',
            quantity,
            asin,
            fnsku,
            title,
            merchantSku,
          });
        });
      }
      
    }
  };

  const calculatePalletDeliveries = async () => {
    const shipments = detailedShipments;
    if (!shipments || shipments.length === 0) {
      console.log('Ratesheet has not been fetched yet.');
      return; // Exit if shipments are not available
    }
  
    try {
      let totalPalletCount = 0;
      
      // Group shipments by base client name
      const shipmentsByBaseClient = shipments.reduce((acc, shipment) => {
        
        const client = clients.find(client => client.id === shipment.outboundInfo.shipment.team_id);
        if (!client) {
          console.error(`Client not found for team_id: ${shipment.outboundInfo.shipment.team_id}`);
          return acc;
        }
  
        const clientNameBase = client.name.replace(/(\s*\(CA\)|\s*\(US\))$/i, '');
  
        if (!acc[clientNameBase]) {
          acc[clientNameBase] = [];
        }
  
        acc[clientNameBase].push(shipment);
        return acc;
      }, {});
  
      // Iterate over each base client to calculate pallet deliveries
      for (const clientNameBase in shipmentsByBaseClient) {
        if (shipmentsByBaseClient.hasOwnProperty(clientNameBase)) {
          // Fetch and calculate pallet deliveries for this base client
          const { palletCount, totalCharge } =
            await fetchAndCalculatePalletDeliveries(
              clientNameBase,
              startDate,
              endDate
            );
  
          console.log(
            `Pallet Count for ${clientNameBase}: ${palletCount}, Total Charge: $${totalCharge.toFixed(2)}`
          );
  
          totalPalletCount += palletCount;
  
          // If there are any pallet deliveries, add them to the service charges
          if (palletCount > 0) {
            setServicesAdded((prevServices) => [
              ...prevServices,
              {
                serviceId: 'ltl-delivery-receiving',
                name: 'Delivery Receiving (LTL)',
                charge: 10,
                type: 'pallet-charge',
                shipmentId: 'N/A', // Not tied to a specific shipment',
                origin: 'pallet-delivery',
                quantity: palletCount,
                totalCharge: totalCharge,
                tax: 0,
              },
            ]);
          }
        }
      }
  
      console.log(`Total Pallet Count: ${totalPalletCount}`);
    } catch (error) {
      console.error('Error calculating pallet deliveries:', error);
    }
  };
  
  // Fetch client shipments based on teamId and date range
  // Update the `fetchClientShipments` function
  const fetchClientShipments = async () => {
    setLoading(true);
    setDetailedShipments([]);
    fetchStandardBoxes();
    fetchRateSheet();
  
    const startOfDay = new Date(new Date(startDate).setHours(0, 0, 0, 0));
    const endOfDay = new Date(new Date(endDate).setHours(23, 59, 59, 999));
  
    try {
      const teamIds = teamId.split(','); // Split by comma to handle multiple IDs
      let allFilteredShipments = [];
  
      for (const id of teamIds) {
        let page = 1;
        let noResultPages = 0;
  
        while (page <= 10) {
          const response = await fetch(
            `${API_BASE_URL}/shipments/outbound?page=${page}&api_token=${API_TOKEN}`,
            {
              headers: {
                'X-Selected-Client-Id': id, // Use individual team ID here
                'Content-Type': 'application/json',
              },
            }
          );
  
          if (!response.ok) {
            throw new Error('Failed to fetch client shipments');
          }
  
          const shipmentData = await response.json();
  
          const filteredShipments = shipmentData.data.filter((shipment) => {
            const shippedAt = shipment.shipped_at ? new Date(shipment.shipped_at) : null;
            const updatedAt = new Date(shipment.updated_at);
  
            const isWithinDateRange =
              (shippedAt && shippedAt >= startOfDay && shippedAt <= endOfDay) ||
              (!shippedAt && updatedAt >= startOfDay && updatedAt <= endOfDay);
  
            return (
              (shipment.status === 'closed' || shipment.status === 'shipped') &&
              isWithinDateRange
            );
          });
  
          allFilteredShipments = [...allFilteredShipments, ...filteredShipments];
  
          if (filteredShipments.length === 0) {
            noResultPages += 1;
            if (noResultPages >= 3) {
              break;
            }
          } else {
            noResultPages = 0;
          }
  
          page += 1;
          await delay(100);
        }
      }
  
      setClientShipments(allFilteredShipments);
  
      // Initialize servicesAdded as an empty array
      let newServicesAdded = [];
  
      const detailedShipments = await Promise.all(
        allFilteredShipments.map(async (shipment) => {
          const outboundInfo = await fetchOutboundInfo(shipment.id);
          const outboundItems = await fetchOutboundItems(shipment.id);
          const plans = await fetchPlans(shipment.id);
  
          let shipmentServicesAdded = [];
          await processOutboundItems(shipment, outboundItems, shipmentServicesAdded);
  
          if (outboundInfo.shipment.company_services) {
            outboundInfo.shipment.company_services.forEach((service) => {
              const existingService = shipmentServicesAdded.find(
                (s) => s.serviceId === service.id && s.shipmentId === shipment.id
              );
  
              if (!existingService) {
                shipmentServicesAdded.push({
                  serviceId: service.id,
                  name: service.name,
                  charge: service.charge,
                  type: service.type,
                  shipmentId: shipment.id,
                  origin: 'outboundInfo',
                  quantity: service.pivot.quantity,
                  asin: '',
                  fnsku: '',
                  title: '',
                  merchantSku: '',
                });
              }
            });
          }
  
          newServicesAdded = [...newServicesAdded, ...shipmentServicesAdded];
  
          return {
            id: shipment.id,
            name: shipment.name,
            status: shipment.status,
            shipped_at: shipment.shipped_at,
            updated_at: shipment.updated_at,
            shipped_items_count: shipment.shipped_items_count,
            sku_count: shipment.sku_count,
            outboundInfo,
            outboundItems,
            plans,
            servicesAdded: shipmentServicesAdded,
          };
        })
      );
  
      setServicesAdded(newServicesAdded); // Set servicesAdded state here
  
      setDetailedShipments(detailedShipments);
  
      calculateStandardBoxes(detailedShipments);
  
      const totalUnitsShipped = calculateTotalUnitsShipped(detailedShipments);
  
      calculateUSAWeightFees(detailedShipments, clients);
  
      await calculatePalletDeliveries(detailedShipments, clients);
  
      await calculateServiceCharges(detailedShipments);
      const autoClientTier = determineClientTier(totalUnitsShipped);
      setClientTier(autoClientTier);
  
    } catch (error) {
      console.error('Error fetching client shipments:', error);
    } finally {
      setLoading(false);
    }
  };
  

  const fetchOutboundInfo = async (shipmentId) => {
    try {
      const response = await fetch(
        `${API_BASE_URL}/shipments/outbound/${shipmentId}?api_token=${API_TOKEN}`
      );

      if (!response.ok) {
        throw new Error('Failed to fetch outbound info');
      }

      const outboundInfo = await response.json();

      return outboundInfo;
    } catch (error) {
      console.error('Error fetching outbound info:', error);
      return null;
    }
  };

  const fetchOutboundItems = async (shipmentId) => {
    try {
      const response = await fetch(
        `${API_BASE_URL}/shipments/outbound/${shipmentId}/outbound-shipment-item?api_token=${API_TOKEN}`
      );

      if (!response.ok) {
        throw new Error('Failed to fetch outbound items');
      }

      const outboundItems = await response.json();

      return outboundItems;
    } catch (error) {
      console.error('Error fetching outbound items:', error);
      return null;
    }
  };

  const handleCopy = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      console.log('Copied to clipboard!');
    });
  };

  const calculateAndAddBillableBoxes = async () => {
    // Recalculate standard boxes based on the current shipments
    await calculateStandardBoxes(detailedShipments);

    // Add the calculated billable boxes to the servicesAdded list
    if (billableBoxes > 0) {
        const service = {
            serviceId: 'billable-boxes',
            name: 'Billable Boxes',
            charge: BOX_RATE, // Use your BOX_RATE constant or any specific rate per box
            type: 'box-charge',
            shipmentId: 'N/A', // Not tied to a specific shipment
            origin: 'standard-box',
            quantity: billableBoxes,
            totalCharge: billableBoxes * BOX_RATE,
        };

        setServicesAdded((prevServices) => [...prevServices, service]);

        // Recalculate service charges
        calculateServiceCharges(detailedShipments);
    }
};



  const fetchPlans = async (shipmentId) => {
    try {
      const response = await fetch(
        `${API_BASE_URL}/fba-transport/v0/plans?outbound_shipment_id=${shipmentId}&api_token=${API_TOKEN}`
      );

      if (!response.ok) {
        throw new Error('Failed to fetch plans');
      }

      const plansData = await response.json();

      // Filter plans based on the given conditions
      const filteredPlans = plansData.data.filter((plan) => {
        return (
          plan.is_accepted && !plan.is_marked_as_deleted && !plan.is_rejected
        );
      });

      // Check if there are any plans that are accepted but not marked as shipped
      const unshippedPlans = filteredPlans.filter(
        (plan) => !plan.is_marked_as_shipped
      );

      // if (unshippedPlans.length > 0) {
      //   setWarnings((prevWarnings) => [
      //     ...prevWarnings,
      //     `Warning: Shipment ID ${shipmentId} has plans that are accepted but not marked as shipped.`,
      //   ]);
      // }

      return filteredPlans; // Return the filtered plans data
    } catch (error) {
      console.error('Error fetching plans:', error);
      return null;
    }
  };

  // Function to calculate the total bill amount with tax
  const calculateTotalBillWithTax = () => {
    return serviceCharges.reduce((total, charge) => {
      const taxRate = selectedTaxes[charge.serviceId] ? 0.13 : 0; // 13% tax if selected
      const taxAmount = charge.totalCharge * taxRate;
      const totalWithTax = charge.totalCharge + taxAmount;
      return total + totalWithTax;
    }, 0);
  };

  // Handle toggling tax for individual services
  const handleTaxToggle = (serviceId) => {
    setSelectedTaxes((prev) => ({
      ...prev,
      [serviceId]: !prev[serviceId],
    }));
  };

  // Apply tax to all services
  const handleApplyTaxToAll = () => {
    const allSelected = !applyTaxToAll;
    setApplyTaxToAll(allSelected);

    const newSelectedTaxes = {};
    serviceCharges.forEach((charge) => {
      newSelectedTaxes[charge.serviceId] = allSelected;
    });

    setSelectedTaxes(newSelectedTaxes);
  };

  // Function to handle the deletion of a service
  // Function to handle the deletion of a service
  const handleDeleteService = useCallback((serviceId) => {
    // Remove the service with the specified serviceId
    const updatedServices = servicesAdded.filter(service => service.serviceId !== serviceId);

    console.log("Deleting service with ID:", serviceId);
    console.log("Updated Services:", updatedServices);

    // Update the services state
    setServicesAdded(updatedServices);

    // Recalculate service charges after deletion
    calculateServiceCharges(updatedServices);
  }, [servicesAdded]);


  useEffect(() => {
      fetchData();
    
  }, []);
  
  

  if (loading) {
    return <Spinner animation="border" />;
  }
  
  return (
    <Container style={{ paddingBottom: '150px' }}>
            {loading && <LoadingOverlay />}
            <h4 className="my-4">
  Client Shipments -  
  {clients && clients.length > 0
    ? (() => {
        // Split the teamId by comma and take the first ID
        const firstTeamId = teamId.split(',')[0].trim();
        
        // Find the client by the firstTeamId
        const client = clients.find(client => client.id == firstTeamId);

        // If a client is found, clean the name by removing (CA) or (US)
        const clientName = client ? client.name.replace(/\s*\(CA\)|\s*\(US\)/g, '') : null;

        return clientName || 'Client Not Found';
      })()
    : 'Loading...'}
</h4>

      {warnings.length > 0 && (
        warnings.map((warning, index) => (
          <Alert key={index} variant="warning">
            {warning}
          </Alert>
        ))
      )}
  
      <Button variant="primary" onClick={handleApplyTaxToAll}>
        {applyTaxToAll ? 'Remove Tax from All' : 'Apply Tax to All'}
      </Button>
  
      <Button variant="secondary" onClick={() => setShowStorageModal(true)}>
        Add Storage Fee
      </Button>
  <Button variant={applyCcAdminFee ? "danger" : "primary"} onClick={() => {
  setApplyCcAdminFee(!applyCcAdminFee);
  calculateCcAdminFee(totalBillWithTax);
}}>
  {applyCcAdminFee ? 'Remove CC Admin Fee' : 'Add CC Admin Fee'}
</Button>
<Button 
  variant="warning" 
  onClick={calculateMinimumAdjustmentFee} 
  disabled={totalBillWithTax >= 500}
>
  Add Minimum Adjustment Fee
</Button>

<Button variant="info" onClick={fetchAndCalculateFDANotice}>
  Calculate FDA Prior Notice
</Button>

<Button variant="success" onClick={() => setShowCustomServiceModal(true)}>
  Add Custom Service Charge
</Button>


      <h4 className="my-4">Select Stripe Customer</h4>
      <Select
    options={squareCustomerOptions}
    value={selectedSquareCustomer}
    onChange={(option) => setSelectedSquareCustomer(option)}
    placeholder="Select a Square customer"
    isSearchable
/>
  
  <h4 className="my-4">Invoice Notes</h4>
    <Form.Group controlId="invoiceNotes">
      <Form.Label>Notes</Form.Label>
      <Form.Control
        as="textarea"
        rows={3}
        placeholder="Add any notes for the invoice..."
        value={invoiceNotes}
        onChange={(e) => setInvoiceNotes(e.target.value)}
      />
    </Form.Group>
  
      <Button variant="success" onClick={generateInvoice}>
        Generate Invoice
      </Button>
  
      <h4 className="my-4">Select Client Tier</h4>
      <div className="mb-4">
        {Array.from({ length: 4 }, (_, i) => i + 1).map((tier) => (
          <div key={tier} className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="radio"
              name="clientTier"
              id={`tier${tier}`}
              value={tier}
              checked={clientTier === tier}
              onChange={() => {
                setClientTier(tier);
                calculateServiceCharges(detailedShipments);
              }}
            />
            <label className="form-check-label" htmlFor={`tier${tier}`}>
              Tier {tier}
            </label>
          </div>
        ))}
      </div>
      <div>
        <h2>Box Usage Summary</h2>
        <table>
          <thead>
            <tr>
              <th>Box Name</th>
              <th>Count</th>
            </tr>
          </thead>
          <tbody>
            {Object.entries(boxUsage).map(([name, count]) => (
              <tr key={name}>
                <td>{name}</td>
                <td>{count}</td>
              </tr>
            ))}
          </tbody>
        </table>
  
        <h3>Total Boxes</h3>
        <p>Total Boxes Overall: {totalBoxesOverall}</p>
        <p>Total Units Shipped: {totalUnitsShipped}</p>
        <p>Waived Boxes: {waivedBoxes}</p>
        <p>Billable Boxes: {billableBoxes}</p>
        <Button variant="primary" onClick={calculateAndAddBillableBoxes}>
  Calculate and Add Billable Boxes
</Button>



      </div>
      <h4 className="my-4">Total Bill Amount</h4>
      <p>Total Amount (including tax): ${totalBillWithTax.toFixed(2)}</p>
  
      {serviceCharges.length > 0 && (
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Service Name</th>
              <th>Rate Charged</th>
              <th>Quantity</th>
              <th>Total Charges</th>
              <th>Tax</th>
              <th>Total With Tax</th>
              <th>Add Tax</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {serviceCharges.map((charge, index) => {
              const taxRate = selectedTaxes[charge.serviceId] ? 0.13 : 0;
              const taxAmount = charge.totalCharge * taxRate;
              const totalWithTax = charge.totalCharge + taxAmount;
  
              return (
                <React.Fragment key={index}>
                  <tr
                    onClick={() =>
                      setExpandedService(expandedService === index ? null : index)
                    }
                  >
                    <td>{charge.serviceName}</td>
                    <td>
                      {typeof charge.rate === 'number'
                        ? charge.rate.toFixed(2)
                        : 'N/A'}
                    </td>
                    <td>{charge.quantity}</td>
                    <td>{charge.totalCharge.toFixed(2)}</td>
                    <td>{taxAmount.toFixed(2)}</td>
                    <td>{totalWithTax.toFixed(2)}</td>
                    <td>
                      <input
                        type="checkbox"
                        checked={!!selectedTaxes[charge.serviceId]}
                        onChange={(e) => {
                          e.stopPropagation();
                          handleTaxToggle(charge.serviceId);
                        }}
                      />
                    </td>
                    <td>
                      <Button
                        variant="danger"
                        onClick={() => handleDeleteService(charge.serviceId)}
                      >
                        Delete
                      </Button>
                    </td>
                  </tr>
                  {expandedService === index && (
                    <tr>
                      <td colSpan="8">
                        <Accordion>
                          <Accordion.Item eventKey="0">
                            <Accordion.Header>Breakdown Details</Accordion.Header>
                            <Accordion.Body>
                              <Table striped bordered hover>
                                <thead>
                                  <tr>
                                    <th>Shipment ID</th>
                                    <th>Quantity</th>
                                    <th>ASIN</th>
                                    <th>FNSKU</th>
                                    <th>Title</th>
                                    <th>Merchant SKU</th>
                                    <th>Total Charge</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {charge.breakdown.map(
                                    (detail, detailIndex) => (
                                      <tr key={detailIndex}>
                                        <td>{detail.shipmentId}</td>
                                        <td>{detail.quantity}</td>
                                        <td>{detail.asin}</td>
                                        <td>{detail.fnsku}</td>
                                        <td>{detail.title}</td>
                                        <td>{detail.merchantSku}</td>
                                        <td>{detail.totalCharge.toFixed(2)}</td>
                                      </tr>
                                    )
                                  )}
                                </tbody>
                              </Table>
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              );
            })}
          </tbody>
        </Table>
      )}
  
      {detailedShipments.length === 0 ? (
        <p>No shipments found for the selected date range and client.</p>
      ) : (
        <Accordion defaultActiveKey="0">
  {detailedShipments.map((shipment, index) => (
    <Accordion.Item eventKey={index.toString()} key={shipment.id}>
<Accordion.Header>
  Shipment ID: {shipment.id} - {shipment.name} - {' '}
  {shipment.outboundInfo &&
  clients.length > 0
    ? clients.find(client => client.id == shipment.outboundInfo.shipment.team_id).name || 'Client Not Found'
    : 'Loading...'}
</Accordion.Header>


      <Accordion.Body>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Shipment ID</th>
              <th>Name</th>
              <th>Status</th>
              <th>Shipped At</th>
              <th>Updated At</th>
              <th>Units</th>
              <th>SKUs</th>
              <th>Plans Count</th>
              <th>Units in Plans</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{shipment.id}</td>
              <td>{shipment.name}</td>
              <td>{shipment.status}</td>
              <td>{shipment.shipped_at || 'N/A'}</td>
              <td>{shipment.updated_at}</td>
              <td>{shipment.shipped_items_count || 0}</td>
              <td>{shipment.sku_count || 0}</td>
              <td>{shipment.plans ? shipment.plans.length : 0}</td>
              <td
                style={{
                  color:
                    shipment.plans &&
                    shipment.plans.reduce(
                      (sum, plan) =>
                        sum +
                        plan.items.reduce(
                          (acc, item) => acc + item.quantity,
                          0
                        ),
                      0
                    ) === shipment.shipped_items_count
                      ? 'green'
                      : 'inherit',
                }}
              >
                {shipment.plans
                  ? shipment.plans.reduce(
                      (sum, plan) =>
                        sum +
                        plan.items.reduce(
                          (acc, item) => acc + item.quantity,
                          0
                        ),
                      0
                    )
                  : 0}
              </td>
            </tr>
          </tbody>
        </Table>
        {shipment.plans && shipment.plans.length > 0 && (
          <Accordion>
          {shipment.plans.map((plan, planIndex) => {
            const totalUnits = plan.items.reduce(
              (sum, item) => sum + item.quantity,
              0
            );
            const totalSKUs = plan.items.length;
        
            // Filter and calculate box data
            const planBoxes = boxData.filter(box => box.FBA_ID === plan.fba_shipment_id);
            const boxCount = planBoxes.length;
            const lastUpdated = planBoxes.reduce((latest, box) => {
              const dateUpdated = new Date(box.DATE_UPDATED);
              return dateUpdated > latest ? dateUpdated : latest;
            }, new Date(0));
        
              return (
                <Accordion.Item
                  eventKey={planIndex.toString()}
                  key={plan.id}
                >
<Accordion.Header>
      FBA ID: {plan.fba_shipment_id}
      <Button 
        variant="outline-secondary" 
        size="sm" 
        onClick={() => handleCopy(plan.fba_shipment_id)}
        style={{ marginLeft: '10px' }}
      >
        Copy to Clipboard
      </Button>
    </Accordion.Header>                   <Accordion.Body>
        <p>
          <strong>Box Count:</strong> {boxCount}
        </p>
        <p>
          <strong>Box Last Updated:</strong> {lastUpdated.toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
          })} at {lastUpdated.toLocaleTimeString('en-US')}
        </p>
        <p>
                      <strong>Name:</strong> {plan.name}
                    </p>
                    <p>
                      <strong>FBA Shipment ID:</strong>{' '}
                      {plan.fba_shipment_id}
                    </p>
                    <p>
                      <strong>Mode:</strong> {plan.mode}
                    </p>
                    <p>
                      <strong>Total Units:</strong> {totalUnits}
                    </p>
                    <p>
                      <strong>Total SKUs:</strong> {totalSKUs}
                    </p>
                    <p>
                      <strong>Accepted:</strong>{' '}
                      {plan.is_accepted ? 'Yes' : 'No'}
                    </p>
                    <p>
                      <strong>Deleted:</strong>{' '}
                      {plan.is_marked_as_deleted ? 'Yes' : 'No'}
                    </p>
                    <p>
                      <strong>Rejected:</strong>{' '}
                      {plan.is_rejected ? 'Yes' : 'No'}
                    </p>
                    <p>
                      <strong>Marked as Shipped:</strong>{' '}
                      {plan.is_marked_as_shipped ? 'Yes' : 'No'}
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
              );
            })}
          </Accordion>
        )}
      </Accordion.Body>
    </Accordion.Item>
  ))}
</Accordion>

      )}
  
      <Modal show={showStorageModal} onHide={() => setShowStorageModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Add Storage Fee</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="pallets">
              <Form.Label>Pallets</Form.Label>
              <Form.Control
                type="number"
                placeholder="Enter number of pallets"
                value={pallets}
                onChange={(e) => setPallets(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="cbm">
              <Form.Label>CBM</Form.Label>
              <Form.Control
                type="number"
                placeholder="Enter CBM"
                value={cbm}
                onChange={(e) => setCbm(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="customAmount">
              <Form.Label>Custom Amount ($)</Form.Label>
              <Form.Control
                type="number"
                placeholder="Enter custom amount"
                value={customAmount}
                onChange={(e) => setCustomAmount(e.target.value)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowStorageModal(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={handleAddStorageFee}>
            Add Fee
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showCustomServiceModal} onHide={() => setShowCustomServiceModal(false)}>
  <Modal.Header closeButton>
    <Modal.Title>Add Custom Service Charge</Modal.Title>
  </Modal.Header>
  <Modal.Body>
    <Form>
      <Form.Group controlId="customServiceName">
        <Form.Label>Service Name</Form.Label>
        <Form.Control
          type="text"
          placeholder="Enter service name"
          value={customServiceName}
          onChange={(e) => setCustomServiceName(e.target.value)}
        />
      </Form.Group>
      <Form.Group controlId="customServiceRate">
        <Form.Label>Rate Charged</Form.Label>
        <Form.Control
          type="number"
          placeholder="Enter rate charged"
          value={customServiceRate}
          onChange={(e) => setCustomServiceRate(e.target.value)}
        />
      </Form.Group>
      <Form.Group controlId="customServiceQuantity">
        <Form.Label>Quantity</Form.Label>
        <Form.Control
          type="number"
          placeholder="Enter quantity"
          value={customServiceQuantity}
          onChange={(e) => setCustomServiceQuantity(e.target.value)}
        />
      </Form.Group>
    </Form>
  </Modal.Body>
  <Modal.Footer>
    <Button variant="secondary" onClick={() => setShowCustomServiceModal(false)}>
      Close
    </Button>
    <Button variant="primary" onClick={handleAddCustomService}>
      Add Service
    </Button>
  </Modal.Footer>
</Modal>

      
    </Container>
  );
  
};

export default ClientDetail;
